<template>
    <div>
        <!-- signature structure  -->
        <b-media class="" @click="openSignature('supervisor')" v-if="crewSign.image == null">
                                                    
            <b-avatar ref="supervisorS" :src="doc_icon" variant="light-warning" rounded style="height: 200px; width: auto"/>

            <div class="d-flex flex-wrap">
                <input ref="supervisorSInput" type="file" class="d-none" @input="crewUpload" >
            </div>

        </b-media>

        <img :src="crewSign.image" v-if="crewSign.image != null" class="rounded" style="background: #ffffff;" width="200px" height="180">
        <br/>
        <b-button size="sm" variant="danger" class="mt-1 px-2 py-1" v-if="crewSign.image != null" @click="removeSign('supervisor')">
            <feather-icon icon="XIcon" />
        </b-button>

        <!-- signature Model -->
        <b-modal centered hide-footer
            id="signatureModelCrew"
            ref="signatureModelCrew"
            title="Signature"
            no-close-on-backdrop
            @hide="hideSigantureCrew"
            @show="hideSigantureCrew"
        >
            <b-row>
                <b-col md="12">
                    <VueSignaturePad
                        id="signature"
                        width="100%"
                        height="300px"
                        ref="signaturePad"
                        :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3 }"
                    />
                </b-col>
            </b-row>

            <b-row class="mt-2">

                <b-col md="4">
                    <b-button variant="primary" class="mt-0 w-100" @click="uploadSign()">
                        <span class="text-nowrap">Upload</span>
                    </b-button>
                </b-col>

                <b-col md="4">
                    <b-button variant="danger" class="mt-0 w-100" @click="clearSignature">
                        <span class="text-nowrap">Clear</span>
                    </b-button>
                </b-col>

                <b-col md="4">
                    <b-button variant="warning" class="mt-0 w-100 float-right" @click="saveSignature">
                        <span class="text-nowrap">Submit</span>
                    </b-button>
                </b-col>
                
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import {
    BFormTextarea, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormFile
} from 'bootstrap-vue';
import VueCropper from 'vue-cropperjs';
import Bus from "../../../event-bus";

export default{
    name: 'CrewSignature',
    components: {
        BFormTextarea, BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, BAvatar, BTable, BModal, VueCropper, BFormRadio, BFormTimepicker, BFormFile
    },
    data(){
        return{
            // signature vars
                doc_icon: require('@/assets/images/doc.png'),
                crewSign : {
                    image:null,
                    name:'',
                    // type: 'supervisor',
                    default:'no'
                },
                openedSignModelCrew:null,
        }
    },
    methods: {
        // signature functions
        hideSigantureCrew(){
            this.openedSignModelCrew = null;
        },

        openSignature(type){
            if (this.openedSignModelCrew == null) {
                this.$refs['signatureModelCrew'].show();
                this.openedSignModelCrew = type;
            }
        },

        uploadSign(){                
            if (this.openedSignModelCrew == 'supervisor') {
                this.$refs['supervisorSInput'].click();
            }
            if (this.openedSignModelCrew == 'client') {
                this.$refs['clientSInput'].click();
            }
            this.$refs['signatureModelCrew'].hide();
        },

        clearSignature(){
            this.$refs.signaturePad.clearSignature();
        },

        saveSignature(){
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
            if (isEmpty) {
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: 'Signature is required',
                    showConfirmButton: false,
                    timer: 1500
                })
            }else{
                if (this.openedSignModelCrew == 'supervisor') {
                    this.crewSign.image = data;
                    this.crewSign.name = 'signature.png';
                    this.crewSign.type =  'supervisor';
                    this.crewSign.default = 'no';
                } else {
                    this.clientSign.image = data;
                    this.clientSign.name = 'signature.png';
                }
                this.$refs['signatureModelCrew'].hide();

                // send image data to parent
                Bus.$emit('update-crew-image', {data: this.crewSign});
            }
        },

        crewUpload(event){
            var input = event.target;
            var files = event.target.files

            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var image = input.files[0];

                if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
                    Swal.fire({
                        position: 'center',
                        icon: 'warning',
                        title: 'Please upload .png, .jpeg, .jpg images only',
                        showConfirmButton: false,
                        timer: 1500
                    });

                    this.$refs.supervisorSInput.value=null;

                } else if (image.size > 2097152) {
                    Swal.fire({
                        position: 'center',
                        icon: 'warning',
                        title: 'Maximum 2 MB files allowed to be upload.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.$refs.supervisorSInput.value=null;
                } else {
                    reader.onload = (e) => {
                        this.crewSign.image = e.target.result;
                        this.crewSign.name = image.name;
                        this.crewSign.type =  'supervisor';
                        this.crewSign.default = 'no';
                    }
                    // send image data to parent
                    Bus.$emit('update-crew-image', {index: this.index, data: this.crewSign});
                }
            }
        },
            
        removeSign(sign){
            this.crewSign.image = null;
            this.crewSign.name = '';
            this.crewSign.default = 'no';
            Bus.$emit('remove-crew-image', this.index);
        },
    },
    mounted(){
        Bus.$on('update-crew-image-incoming', (data) => {
            this.crewSign = data.data;
        })
    }
}
</script>
